import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import { getAccessToken } from '../configuration/tokenHandling/tokenSlice';

import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { useEffect } from 'react';
import PageLayout from './PageLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

const AppLayout = () => {
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    const accessToken = useAppSelector(getAccessToken);

    useEffect(() => {
        const root = document.getElementById('root');
        if (!accessToken && root?.style.minHeight) {
            root.style.removeProperty('min-height');
        }
    }, [accessToken]);

    if (!displayMessages) {
        return null;
    }

    return (
        <ApplicationLayout>
            <IntlProvider
                defaultLocale={DEFAULT_LOCALE}
                key={userLocale}
                locale={userLocale}
                messages={displayMessages}
            >
                <NotificationsContainer />
                <PageLayout />
            </IntlProvider>
        </ApplicationLayout>
    );
};

export default AppLayout;
