export function extractQueryParameter(queryKey: string): string | undefined {
    const params = window.location.search.substring(1);

    const listOfParams = params.split('&');
    for (const keyValue of listOfParams) {
        const keyValueTuple = keyValue.split('=');
        const key = keyValueTuple[0];
        if (key === queryKey && keyValueTuple.length > 1) {
            return keyValueTuple[1];
        }
    }
}
