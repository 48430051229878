export function fileTypeHelper(): { mimeTypes: string[]; extensions: string[] } {
    const mimeToExtension: { [key: string]: string | string[] } = {
        'application/pdf': 'pdf',
        'image/jpeg': ['jpg', 'jpeg'],
        'image/jpg': 'jpg',
        'image/png': 'png',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
        'application/msword': 'doc',
        'text/plain': 'txt',
        'text/csv': 'csv',
        'text/tsv': 'tsv',
    };

    const acceptedFileTypes = Object.keys(mimeToExtension);
    const extensions = new Set<string>();

    for (const mimeType of acceptedFileTypes) {
        const ext = mimeToExtension[mimeType];
        if (Array.isArray(ext)) {
            ext.forEach((e) => extensions.add(e));
        } else if (ext) {
            extensions.add(ext);
        }
    }

    return {
        mimeTypes: acceptedFileTypes,
        extensions: Array.from(extensions),
    };
}
