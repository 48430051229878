import React from 'react';
import { useIntl } from 'react-intl';

export function TitleInput({ setValue }: { setValue: (value: string) => void }) {
    const intl = useIntl();
    const id = 'contactForm.title';
    const onChange = (it: React.ChangeEvent<HTMLSelectElement>) => setValue(it.target.value);
    return (
        <div className="form-group">
            <label className="control-label" htmlFor={id}>
                {intl.formatMessage({ id })}
            </label>
            <select className="form-control" onChange={onChange} id={id}>
                <option value="">{intl.formatMessage({ id: 'contactForm.pleaseSelect' })}</option>
                <option value="Ms">{intl.formatMessage({ id: 'contactForm.title.ms' })}</option>
                <option value="Mr">{intl.formatMessage({ id: 'contactForm.title.mr' })}</option>
            </select>
        </div>
    );
}
