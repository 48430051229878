import React from 'react';
import { FormattedMessage } from 'react-intl';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';

export const SuccessPage = ({ displayTextId, children }: { displayTextId: string; children?: React.ReactNode }) => {
    const showCloseButton = window.top === window.self;
    const closeWindow = () => window.close();
    return (
        <ApplicationLayout>
            <ApplicationLayout.Body>
                <div className="module-content-wrapper">
                    <div className="module-content">
                        <div className="container-fluid fluid-small panel">
                            <div className="text-center panel-body margin-20">
                                <div className="subscription-icon text-success">
                                    <span className="rioglyph rioglyph-ok text-size-h1" aria-hidden="true" />
                                </div>
                                <h3 className="text-success">
                                    <FormattedMessage id={displayTextId} />
                                </h3>
                                {children}
                                {showCloseButton && (
                                    <div className="margin-top-20">
                                        <button className={'btn btn-primary'} onClick={closeWindow}>
                                            <FormattedMessage id={'contactForm.close'} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};
