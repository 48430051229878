import { ApiPartner, ApiPartnerConnectionsCodec, ApiPartnersCodec, PartnerConnection } from './partner.types';
import { decodeJson } from './api';
import { Partner } from './partnerApi';

export const mapApiPartnerConnections = (apiPartnerConnections: unknown): Array<PartnerConnection> => {
    const decoded = decodeJson(ApiPartnerConnectionsCodec)(apiPartnerConnections);
    return decoded.items.map((apiPartnerConnection: any) => ({
        id: apiPartnerConnection.id,
        partnerName: apiPartnerConnection.partner_name,
        status: apiPartnerConnection.status,
        createdAt: apiPartnerConnection.created_at,
    }));
};

export const mapApiPartners = (apiPartners: any): Array<Partner> => {
    return decodeJson(ApiPartnersCodec)(apiPartners).items.map((apiPartner: ApiPartner) => ({
        partnerName: apiPartner.partner_name,
        partnerDisplayName: apiPartner.display_name
            ? apiPartner.display_name
            : getFallbackDisplayName(apiPartner.partner_name),
        privacyPolicyUrl: getPolicyUrl(apiPartner),
    }));
};

const getPolicyUrl = (apiPartner: ApiPartner) =>
    `https://rio.cloud/fileadmin/Marketplace/RIO/Partner/partner_${apiPartner.partner_name}_privacy_policy.pdf`;

const getFallbackDisplayName = (partnerName: string) =>
    partnerName.replace(/_/g, ' ').replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
