import { trace } from './trace';
import { routeStorage } from '../login/storage';
import { reportErrorToSentry } from './sentry';
import { User, UserManager } from 'oidc-client-ts';

const param = (window: any, regex: any, defaultValue = null) => {
    let result = defaultValue;

    decodeURI(window.location.href).replace(regex, (_, it) => {
        result = it;
        return it;
    });
    return result;
};

const saveCurrentRoute = () => {
    const initialRoute = [window.location.pathname, window.location.search].join('').replace(/^#/u, '');

    routeStorage.saveRoute(initialRoute);

    trace('saving initial route', initialRoute);
};

const isFreshRedirect = Boolean(param(window, /code=([^&]+)/u));

export const handleSuccess = (user: User | null) => {
    const initialRoute = routeStorage.getRoute();
    trace('initialRoute lookup', initialRoute);
    if (initialRoute && isFreshRedirect) {
        trace(`location.replace("${initialRoute}")`);
        window.location.replace(initialRoute);
    }
    routeStorage.discardRoute();

    return Promise.resolve(user);
};

export const attemptInitialSignIn = (userManager: UserManager) => {
    return userManager
        .signinSilent()
        .then(handleSuccess)
        .catch((error: any) => {
            trace('oidc.signinSilent failed, trying page redirect...', error);

            if (isFreshRedirect) {
                trace('oidc.signinSilent.error', 'redirect prevented due to suspicious signin error', error);
                routeStorage.discardRoute();
                reportErrorToSentry(error);
            } else {
                saveCurrentRoute();
                userManager.signinRedirect();
            }

            trace('auth problem?', error);
            return Promise.reject(new Error(`Need to sign in`));
        });
};
