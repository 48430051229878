import { getContactData, getCustomerData } from '../formElements/redux/formElements.redux';
import { getOpenerUrl } from '../gtm/gtmLabels';
import { extractQueryParameter } from '../../utils/queryParameters';
import { RootState } from '../../../configuration/setup/store';
import { getLocale } from '../../../configuration/lang/langSlice';
import { getIdToken } from '../../../configuration/tokenHandling/tokenSlice';

export interface ContactFormContent {
    title?: string;
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    message?: string;
    reason: string;
    phoneNumber?: string;
    locale: string;
    acceptedSavingData: boolean;
    newsletter: boolean;
    accountId?: string;
    userId?: string;
    tenant?: string;
    vin?: string;
    additionalInfo?: string;
    correlationId: string;
    files: File[];
}

export function toContactRequestBody(formContent: ContactFormContent) {
    return {
        title: formContent.title,
        first_name: formContent.firstName,
        last_name: formContent.lastName,
        company: formContent.company,
        email: formContent.email,
        phone_number: formContent.phoneNumber,
        message: formContent.message,
        account_id: formContent.accountId,
        user_id: formContent.userId,
        locale: formContent.locale,
        accept_saving_data_to_account: formContent.acceptedSavingData,
        accept_newsletter: formContent.newsletter,
        contact_reason: formContent.reason,
        opener: getOpenerUrl(),
        partner_id: extractQueryParameter('partnerId'),
        tenant: formContent.tenant,
        vin: formContent.vin,
        additional_info: formContent.additionalInfo,
        correlation_id: formContent.correlationId,
    };
}

export function getContactFormContent(state: RootState): ContactFormContent {
    const locale = getLocale(state);
    const idToken = getIdToken(state);
    const customerData = getCustomerData(state);
    const contactData = getContactData(state);
    const accountId = idToken?.account;
    const userId = idToken?.sub;

    return {
        ...customerData,
        ...contactData,
        locale,
        accountId,
        userId,
    };
}
