import React, { useEffect, useState } from 'react';
import { PartnerRequestForm } from './PartnerRequestForm';
import { loginGuard } from '../../../configuration/login/loginGuard';

export const PartnerForm = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    useEffect(() => {
        document.title = 'RIO Partner Form';
        loginGuard().then(setLoggedIn);
    }, []);
    return loggedIn ? <PartnerRequestForm /> : null;
};
