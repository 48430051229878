import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    getPartnerByPartnerName,
    useGetPartnerConnectionsQuery,
    useGetPartnersQuery,
    usePutPartnerConnectionMutation,
} from '../api/partnerApi';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';
import { PartnerConnectionStatus } from '../api/partner.types';
import { SuccessPage } from '../SuccessPage';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { CustomerCenterLink } from './CustomerCenterLink';
import { PartnerRequestFormContent } from './PartnerRequestFormContent';

export const PartnerRequestForm = () => {
    const accountId = useSelector((state) => {
        // @ts-ignore https://github.com/reduxjs/redux-toolkit/issues/1879
        return state.tokenHandling.idToken?.account;
    });
    const partner = useSelector(getPartnerByPartnerName);
    const partnerConnectionsQuery = useGetPartnerConnectionsQuery(accountId);
    const partnersQuery = useGetPartnersQuery();
    const [putPartnerConnectionMutation, partnerConnectionMutationResult] = usePutPartnerConnectionMutation();

    if (partnerConnectionsQuery.isLoading || partnersQuery.isLoading) {
        return (
            <div className={'margin-top-5pct'}>
                <Spinner />
            </div>
        );
    }

    if (
        (partnerConnectionsQuery.isError && isUnauthorizedError(partnerConnectionsQuery.error)) ||
        (partnersQuery.isError && isUnauthorizedError(partnersQuery.error)) ||
        (partnerConnectionMutationResult.isError && isUnauthorizedError(partnerConnectionMutationResult.error))
    ) {
        return (
            <div className="container-fluid fluid-small">
                <ErrorState headline={<FormattedMessage id="contactForm.unauthorized" />} />
            </div>
        );
    }

    if (partnerConnectionsQuery.isError || !partnerConnectionsQuery.data) {
        return handleError(partnerConnectionsQuery.error || new Error('Undefined returned for partnerConnections.'));
    }
    if (partnersQuery.isError || !partnersQuery.data) {
        return handleError(partnersQuery.error || new Error('Undefined returned for partners.'));
    }
    if (partnerConnectionMutationResult.isSuccess) {
        return (
            <SuccessPage displayTextId="contactForm.successfulSending">
                <CustomerCenterLink />
            </SuccessPage>
        );
    }

    if (!partner) {
        return <div className="row">Partner not found - Configuration error</div>;
    }
    const { partnerName } = partner;

    if (
        partnerConnectionsQuery.data.find((connection) => connection.partnerName === partnerName)?.status ===
        PartnerConnectionStatus.ACTIVATED
    ) {
        return (
            <div className="container-fluid fluid-small margin-top-5pct">
                <CustomState
                    icon="rioglyph-compare"
                    headline={<FormattedMessage id="contactForm.partner.connectionExists" />}
                    message={<CustomerCenterLink />}
                />
            </div>
        );
    }
    const handleSubmit = (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        putPartnerConnectionMutation({ partnerName, accountId });
    };
    return <PartnerRequestFormContent partner={partner} submitRequest={handleSubmit} />;
};

const isUnauthorizedError = (error: FetchBaseQueryError | SerializedError) =>
    error && 'status' in error && (error.status === 401 || error.status === 403);

function handleError(error: FetchBaseQueryError | SerializedError) {
    reportErrorToSentry(error);
    return (
        <div className="container-fluid fluid-small">
            <ErrorState headline={<FormattedMessage id="contactForm.loading.error" />} />
        </div>
    );
}
