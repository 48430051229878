import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactData, CustomerData, FormElementsState, PartnerData } from './types';
import { extractQueryParameter } from '../../../utils/queryParameters';
import { RootState } from '../../../../configuration/setup/store';

function getHideTitle(): boolean {
    return extractQueryParameter('hideTitle') !== undefined;
}

function getReason() {
    if (extractQueryParameter('partnerId')) {
        return 'partner-offering';
    }
    const reason = extractQueryParameter('reason');
    return reason ?? '';
}

const initialState: FormElementsState = {
    partnerData: {
        acceptedSavingData: false,
        message: '',
    },
    customerData: {
        title: '',
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phoneNumber: '',
        tenant: '',
    },
    contactData: {
        hideTitle: getHideTitle(),
        reason: getReason(),
        vin: '',
        additionalInfo: '',
        message: '',
        acceptedSavingData: false,
        newsletter: false,
        correlationId: '',
        files: [],
    },
};

export const { reducer: formElementsReducer, actions: formElementsActions } = createSlice({
    name: 'formElements',
    initialState,
    reducers: {
        setPartnerData: (state: FormElementsState, action: PayloadAction<PartnerData>) => {
            state.partnerData = action.payload;
        },
        setContactData: (state: FormElementsState, action: PayloadAction<ContactData>) => {
            state.contactData = action.payload;
        },
        setHideTitle: (state: FormElementsState, action: PayloadAction<boolean>) => {
            state.contactData.hideTitle = action.payload;
        },
        setReason: (state: FormElementsState, action: PayloadAction<string>) => {
            state.contactData.reason = action.payload;
        },
        setCustomerData: (state: FormElementsState, action: PayloadAction<CustomerData>) => {
            state.customerData = action.payload;
        },
        setFiles: (state: FormElementsState, action: PayloadAction<File[]>) => {
            state.contactData.files = action.payload;
        },
        resetFormElements: () => initialState,
    },
});

const getBase = (state: RootState): FormElementsState => state.formElements;

export const getContactData = (state: RootState) => getBase(state).contactData;
export const getCustomerData = (state: RootState) => getBase(state).customerData;
export const getFiles = (state: RootState) => getBase(state).contactData.files;
export const getPartnerData = (state: RootState) => getBase(state).partnerData;
