import * as t from 'io-ts';
import { isRight } from 'fp-ts/lib/Either';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { config } from '../../../config';
import { ContactFormContent, toContactRequestBody } from './contact';
import axios from 'axios';
import { reportErrorToSentry } from '../../../configuration/setup/sentry';

function postRequest<T>(body?: T): RequestInit {
    return {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body !== undefined ? JSON.stringify(body) : undefined,
    };
}

const backend = config.backend.CONTACT_FORM_SERVICE;
const distributionUrl = config.backend.CLOUDFRONT_DISTRIBUTION;

export async function sendContactContentToServer(formContent: ContactFormContent, token: string): Promise<Response> {
    const body = toContactRequestBody(formContent);

    if (formContent.files.length > 0) {
        try {
            await uploadFiles(formContent.files, token, formContent.correlationId);
        } catch (e) {
            reportErrorToSentry(e, 'Error during file upload');
        }
    }
    return fetch(`${backend}/contacts`, postRequest(body));
}

export function decodeJson<T>(decoder: t.Decoder<unknown, T>) {
    return (json: unknown): T => {
        const validationResult = decoder.decode(json);

        if (isRight(validationResult)) {
            return validationResult.right;
        }
        throw new Error(`Error during parsing response: ${PathReporter.report(validationResult)}`);
    };
}

const uploadFiles = async (files: File[], token: string, correlationId: string) => {
    const axiosInstance = axios.create({
        baseURL: distributionUrl,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const uploadPromises = files.map((file) => {
        return axiosInstance.put(`/${correlationId}__${file.name}`, file);
    });

    return Promise.all(uploadPromises);
};
