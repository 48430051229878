import { useIntl } from 'react-intl';
import React, { useState } from 'react';
import { sendContactContentToServer } from '../api/api';
import { gaLabelsForContactClick, gaLabelsForContactView } from '../gtm/gtmLabels';
import { CustomerFormContainer } from '../formElements/CustomerForm';
import { SubmitForm } from '../formElements/SubmitForm';
import { connect, useSelector } from 'react-redux';
import { ContactFormHeaderContainer } from './ContactFormHeader';
import { ContactMessageFormContainer } from './ContactMessageForm';
import { SuccessPage } from '../SuccessPage';
import { sendAndHandleError } from '../sendAndHandleErrors';
import { ContactFormContent, getContactFormContent } from '../api/contact';
import { RootState } from '../../../configuration/setup/store';
import { getAccessToken } from '../../../configuration/tokenHandling/tokenSlice';

interface Props {
    formContent: ContactFormContent;
}

export const ContactForm = ({ formContent }: Props) => {
    const intl = useIntl();
    const [successfullySent, setSuccessfullySent] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const token = useSelector((state: RootState) => getAccessToken(state));

    const errorMessage = intl.formatMessage({ id: 'contactForm.failedSending' });

    const handleSubmit = (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        sendAndHandleError(setSuccessfullySent, errorMessage, setIsSending, () =>
            sendContactContentToServer(formContent, token ?? '')
        );
    };

    if (successfullySent) {
        return <SuccessPage displayTextId={'contactForm.successfulContact'} />;
    }

    return (
        <div className="container-fluid fluid-small padding-top-5" {...gaLabelsForContactView()}>
            <ContactFormHeaderContainer />
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <CustomerFormContainer />
                </div>
                <div className="row">
                    <ContactMessageFormContainer />
                </div>
                <div className="row padding-bottom-2pct">
                    <SubmitForm
                        isSending={isSending}
                        label={'contactForm.send'}
                        gaTrackingOnSend={gaLabelsForContactClick()}
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: RootState): Props => ({
    formContent: getContactFormContent(state),
});

export const ContactFormContainer = connect(mapStateToProps)(ContactForm);
