import { FormattedMessage } from 'react-intl';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { PrivacyPolicyOpener } from './PrivacyPolicyOpener';

interface Props {
    privacyPolicyUrl: string;
    checkBoxLabel: string;
}

export const PrivacyPolicyCheckbox = ({ checkBoxLabel, privacyPolicyUrl }: Props) => {
    return (
        <Checkbox required={true}>
            <FormattedMessage
                id={checkBoxLabel}
                values={{
                    link: (chunks) => (
                        <PrivacyPolicyOpener privacyPolicyUrl={privacyPolicyUrl}>{chunks}</PrivacyPolicyOpener>
                    ),
                }}
            />
            <span className="required">*</span>
        </Checkbox>
    );
};
